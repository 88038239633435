import { ICoreContext } from '@msdyn365-commerce/core';
import nbNO from './nb-NO.json';
import enUS from './en-US.json';

// Generate with https://jvilk.com/MakeTypes/
export interface IAttributeLocale {
    notFound: string;
    specialBadges: string;
    labels: IAttributeLocaleLabels;
    prefixedAttributes: IAttributeLocalePrefixedAttributes;
    groups: IAttributeLocaleGroups;
    country: string;
    district: string;
    underDistrict: string;
    alcoholPercent: string;
    volumeCl: string;
    producer: string;
    description: string;
    fitsWith: string;
    flavorProfile: IFlavorProfile;
    taste: string;
    rawMaterial: string;
    ingredients: string;
    sugar: string;
    acid: string;
    alternative: IAlternative;
    vintage: string;
    organic: string;
    screwCap: string;
    inventory: string;
    subCategory: string;
    soldOutUntilDate: string;
    lifeCycle: string;
}

export interface IAttributeLocaleLabels {
    yes: string;
    no: string;
    alcoholPercentage: string;
    unitOfMeasure: string;
    aboutProduct: string;
    productInformation: string;
}

export interface IAttributeLocalePrefixedAttributes {
    alcoholPercent: string;
    volumeCl: string;
    producer: string;
    country: string;
    district: string;
    vintage: string;
    soldOutUntilDate: string;
    lifeCycle: string;
}

export interface IAttributeLocaleGroups {
    aboutProduct: string;
}

export interface IFlavorProfile {
    body: string;
    freshness: string;
    sweetness: string;
    bitterness: string;
    tannins: string;
    oak: string;
    smoke: string;
    spiceHerb: string;
    fruit: string;
}

export interface IAlternative {
    prefixed: IAlternativePrefixed;
    alcoholPercent: string;
    sugar: string;
    volumeCl: string;
}

export interface IAlternativePrefixed {
    producer: string;
    country: string;
    district: string;
    volumeCl: string;
}

export function getLocale(langOrContext: string | ICoreContext): IAttributeLocale {
    const lang = typeof langOrContext === 'string' ? langOrContext : langOrContext.request.locale;

    switch (lang) {
        case 'nb-NO':
            return nbNO;
        case 'en-US':
            return {...nbNO, ...enUS};
        default:
            return nbNO;
    }
}
